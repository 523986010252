
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'DailyReportEventDeleteModal',

  emits: ['confirm-delete'],

  setup(props, { emit }) {
    const deleteModal = inject<UseModal<string>>(
      'daily-report-delete-event-modal'
    )

    async function onConfirm() {
      emit('confirm-delete', deleteModal?.state.data)

      deleteModal?.closeModal()
    }

    return {
      deleteModal,
      onConfirm,
    }
  },
})
