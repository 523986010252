<template>
  <div class="q-pa-md">
    <q-dialog v-model="deleteConfirmModal.state.show" :persistent="false">
      <DailyReportEventDeleteModal
        v-if="deleteConfirmModal.state.show"
        @confirm-delete="onConfirmDelete"
      />
    </q-dialog>
    <q-table
      hide-bottom
      dense
      title="Händelser"
      square
      :rows-per-page-options="[0]"
      :rows="eventCopyMap"
      :columns="columns"
      class="dailyReportEventTable"
      separator="cell"
      :bordered="isPrintMode"
      :flat="isPrintMode"
    >
      <template v-slot:top-right>
        <q-btn
          v-if="can('report.dailyReport.create')"
          icon-right="mdi-plus-circle-outline"
          label="Lägg till"
          color="white"
          size="small"
          @click="onAddEvent"
          outline
        />
      </template>
      <template v-slot:body-cell-from="props">
        <q-td :props="props" style="padding: 0; vertical-align: top">
          <q-input
            :readonly="!can('report.dailyReport.create')"
            type="time"
            :input-style="{ paddingLeft: '8px' }"
            v-model="eventCopyUuidMap[props.row.uuid].from"
            :class="[
              !eventCopyUuidMap[props.row.uuid].from && 'dailyReportErrorInput',
            ]"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-to="props">
        <q-td :props="props" style="padding: 0; vertical-align: top">
          <q-input
            :readonly="!can('report.dailyReport.create')"
            type="time"
            :input-style="{ paddingLeft: '8px' }"
            v-model="eventCopyUuidMap[props.row.uuid].to"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-tracks="props">
        <q-td :props="props" style="padding: 0; vertical-align: top">
          <q-select
            :readonly="!can('report.dailyReport.create')"
            use-chips
            stack-label
            square
            v-model="eventCopyUuidMap[props.row.uuid].luppTracks"
            use-input
            input-debounce="0"
            option-value="groupNumber"
            option-label="description"
            :options="luppTracks"
            fill-input
            multiple
            map-options
            :placeholder="
              can('report.dailyReport.create') ? 'Välj banor...' : ''
            "
            :class="['dailyReportSelectInput']"
            :input-style="{ paddingLeft: '8px' }"
            transition-show="jump-up"
            transition-hide="jump-up"
          >
          </q-select>
        </q-td>
      </template>

      <template v-slot:body-cell-eventUuid="props">
        <q-td :props="props" style="padding: 0; vertical-align: top">
          <q-select
            style="width: 300px"
            :readonly="!can('report.dailyReport.create')"
            square
            :label="can('report.dailyReport.create') ? 'Välj händelse...' : ''"
            v-model="eventCopyUuidMap[props.row.uuid].eventUuid"
            input-debounce="0"
            option-value="eventUuid"
            option-label="description"
            :options="
              filterEvents(events, eventCopyUuidMap[props.row.uuid].luppTracks)
            "
            map-options
            emit-value
            clearable
            persistent-placeholder
            :class="['dailyReportSelectInput', 'ellipsis']"
            :input-style="{ paddingLeft: '8px' }"
            transition-show="jump-up"
            transition-hide="jump-up"
          >
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section>
                  <q-item-label>{{ scope.opt.description }}</q-item-label>
                  <q-item-label caption>
                    Id: {{ scope.opt.id }}
                    {{
                      scope.opt.trvIds.length
                        ? ` | Trv Ids: ${scope.opt.trvIds.join(', ')}`
                        : ''
                    }}
                    {{
                      scope.opt.causingVehicles.length
                        ? ` | Fordon: ${scope.opt.causingVehicles.join(', ')}`
                        : ''
                    }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </q-td>
      </template>

      <template v-slot:body-cell-description="props">
        <q-td :props="props" style="padding: 0; vertical-align: top">
          <q-input
            :readonly="!can('report.dailyReport.create')"
            placeholder="Övrigt..."
            type="textarea"
            autogrow
            :input-style="{ paddingLeft: '8px' }"
            v-model="eventCopyUuidMap[props.row.uuid].description"
            :class="[
              'dailyReportSelectInput',
              !eventCopyUuidMap[props.row.uuid].description &&
                'dailyReportErrorInput',
            ]"
          />
        </q-td>
      </template>
      <template v-slot:body-cell-_actions="props">
        <q-td :props="props">
          <template v-if="can('report.dailyReport.create')">
            <q-btn
              v-if="props.row._new"
              label="Spara"
              color="secondary"
              :disable="
                !eventCopyUuidMap[props.row.uuid].from ||
                !eventCopyUuidMap[props.row.uuid].description
              "
              size="small"
              :loading="loading.event.create"
              @click="() => onSaveEvent(props.row.uuid)"
            />
            <q-btn
              v-if="isEventDiff(props.row.uuid)"
              :loading="loading.event.update"
              label="Uppdatera"
              color="secondary"
              size="small"
              @click="() => onUpdateEvent(props.row.uuid)"
            />
            <q-btn
              class="q-ml-sm"
              color="negative"
              size="small"
              outline
              :loading="loading.event.delete"
              @click="() => onRemoveEvent(props.row.uuid)"
            >
              <q-icon size="medium" name="mdi-delete" />
            </q-btn>
          </template>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref } from 'vue'
import {
  DailyReportNewEvent,
  useDailyReport,
} from '@/composable/useDailyReport'
import { QTable } from 'quasar'
import { v4 as uuidv4 } from 'uuid'
import { useModal } from '@/composable/useModal'
import DailyReportEventDeleteModal from '@/components/daily-report/DailyReportEventDeleteModal.vue'
import { useLupp } from '@/composable/useLupp'
import format from 'date-fns/format'
import isEqual from 'lodash.isequal'
import { requiredNotEmpty } from '@/common/formValidationRules'
import { useProfile } from '@/composable/useProfile'
import { useEvent } from '@/composable/useEvent'
import { DailyReportEventSection } from '@/api/daily-report/getEventSection'

export default defineComponent({
  name: 'DailyReportEventTable',

  components: {
    DailyReportEventDeleteModal,
  },

  setup() {
    const { can } = useProfile()
    const deleteConfirmModal = useModal()
    provide('daily-report-delete-event-modal', deleteConfirmModal)

    const {
      data,
      removeEvent,
      date,
      addEvent,
      loading,
      updateEvent,
      isPrintMode,
    } = useDailyReport()
    const { listDailyReportEventSection: events } = useEvent()
    const { data: luppData } = useLupp()

    const eventCopyUuidMap = ref(
      Object.assign(
        {},
        data.value.event.reduce<{ [uuid: string]: DailyReportNewEvent }>(
          (acc, event) => {
            acc[event.uuid] = Object.assign({}, event)
            return acc
          },
          {}
        )
      )
    )

    const eventCopyMap = computed(() => Object.values(eventCopyUuidMap.value))

    const filterEvents = (
      events: DailyReportEventSection[],
      tracks: { description: string }[]
    ) => {
      const _tracks = tracks.map((x) => x.description)
      return events.filter((x) => x.banor.some((b) => _tracks.includes(b)))
    }

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'from',
        label: 'Från*',
        field: 'from',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'tracks',
        label: 'Banor',
        field: 'tracks',
        style: 'width: 430px',
      },
      {
        align: 'left',
        name: 'eventUuid',
        label: 'Händelse',
        field: 'eventUuid',
        style: 'width: 300px',
      },
      {
        align: 'left',
        name: 'description',
        label: 'Fritext*',
        field: 'description',
        style: 'min-width: 100%',
      },
      {
        align: 'right',
        name: '_actions',
        label: '',
        field: '_actions',
        style: 'width: 70px',
      },
    ]

    function onAddEvent() {
      const body = {
        uuid: uuidv4(),
        date: date.value + 'T00:00:00.000Z',
        from: format(new Date(), 'HH:mm'),
        to: format(new Date(), 'HH:mm'),
        luppTracks: [],
        eventUuid: null,
        description: '',
        _new: true,
      }

      eventCopyUuidMap.value[body.uuid] = body
    }

    function onRemoveEvent(uuid: string) {
      deleteConfirmModal.openModal({
        data: uuid,
      })
    }

    function onConfirmDelete(uuid: string) {
      delete eventCopyUuidMap.value[uuid]
      removeEvent(uuid)
    }

    function isEventDiff(uuid: string) {
      const origEvent = data.value.event.find((event) => event.uuid === uuid)
      if (!origEvent) return false

      return !isEqual(origEvent, eventCopyUuidMap.value[uuid])
    }

    function onSaveEvent(uuid: string) {
      delete eventCopyUuidMap.value[uuid]._new
      addEvent(eventCopyUuidMap.value[uuid])
    }

    async function onUpdateEvent(uuid: string) {
      await updateEvent(eventCopyUuidMap.value[uuid])
    }

    const luppTracks = computed(() => {
      return Object.values(
        luppData.value.track
          .map((t) => ({
            groupNumber: t.groupNumber,
            description: t.description,
          }))
          .reduce<{
            [key: string]: { groupNumber: number; description: string }
          }>((acc, track) => {
            if (!acc[track.description]) {
              acc[track.description] = track
            }

            return acc
          }, {})
      )
    })

    return {
      can,
      isPrintMode,
      isEventDiff,
      eventCopyMap,
      eventCopyUuidMap,
      data,
      columns,
      onAddEvent,
      onUpdateEvent,
      deleteConfirmModal,
      onRemoveEvent,
      luppTracks,
      onConfirmDelete,
      onSaveEvent,
      loading,
      requiredNotEmpty,
      events,
      filterEvents,
    }
  },
})
</script>

<style lang="scss">
.dailyReportEventTable {
  .q-table__top {
    background-color: #00a540;
    color: white;
  }

  thead tr:first-child th {
    background-color: #00a540;
    color: white;
  }
}

.dailyReportRightAlignInput input {
  text-align: right;
}

.dailyReportSelectInput .q-field__label {
  padding-left: 8px;
}

.dailyReportErrorInput {
  .q-field__inner:before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
    border: 2px solid red;
    pointer-events: none;
  }
}

.ellipsis .q-field__native span {
  width: 260px;
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
