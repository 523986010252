
import { computed, defineComponent, provide, ref } from 'vue'
import {
  DailyReportNewEvent,
  useDailyReport,
} from '@/composable/useDailyReport'
import QTable from 'quasar/src/components/table/QTable.js';
import { v4 as uuidv4 } from 'uuid'
import { useModal } from '@/composable/useModal'
import DailyReportEventDeleteModal from '@/components/daily-report/DailyReportEventDeleteModal.vue'
import { useLupp } from '@/composable/useLupp'
import format from 'date-fns/format'
import isEqual from 'lodash.isequal'
import { requiredNotEmpty } from '@/common/formValidationRules'
import { useProfile } from '@/composable/useProfile'
import { useEvent } from '@/composable/useEvent'
import { DailyReportEventSection } from '@/api/daily-report/getEventSection'

export default defineComponent({
  name: 'DailyReportEventTable',

  components: {
    DailyReportEventDeleteModal,
  },

  setup() {
    const { can } = useProfile()
    const deleteConfirmModal = useModal()
    provide('daily-report-delete-event-modal', deleteConfirmModal)

    const {
      data,
      removeEvent,
      date,
      addEvent,
      loading,
      updateEvent,
      isPrintMode,
    } = useDailyReport()
    const { listDailyReportEventSection: events } = useEvent()
    const { data: luppData } = useLupp()

    const eventCopyUuidMap = ref(
      Object.assign(
        {},
        data.value.event.reduce<{ [uuid: string]: DailyReportNewEvent }>(
          (acc, event) => {
            acc[event.uuid] = Object.assign({}, event)
            return acc
          },
          {}
        )
      )
    )

    const eventCopyMap = computed(() => Object.values(eventCopyUuidMap.value))

    const filterEvents = (
      events: DailyReportEventSection[],
      tracks: { description: string }[]
    ) => {
      const _tracks = tracks.map((x) => x.description)
      return events.filter((x) => x.banor.some((b) => _tracks.includes(b)))
    }

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'from',
        label: 'Från*',
        field: 'from',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'tracks',
        label: 'Banor',
        field: 'tracks',
        style: 'width: 430px',
      },
      {
        align: 'left',
        name: 'eventUuid',
        label: 'Händelse',
        field: 'eventUuid',
        style: 'width: 300px',
      },
      {
        align: 'left',
        name: 'description',
        label: 'Fritext*',
        field: 'description',
        style: 'min-width: 100%',
      },
      {
        align: 'right',
        name: '_actions',
        label: '',
        field: '_actions',
        style: 'width: 70px',
      },
    ]

    function onAddEvent() {
      const body = {
        uuid: uuidv4(),
        date: date.value + 'T00:00:00.000Z',
        from: format(new Date(), 'HH:mm'),
        to: format(new Date(), 'HH:mm'),
        luppTracks: [],
        eventUuid: null,
        description: '',
        _new: true,
      }

      eventCopyUuidMap.value[body.uuid] = body
    }

    function onRemoveEvent(uuid: string) {
      deleteConfirmModal.openModal({
        data: uuid,
      })
    }

    function onConfirmDelete(uuid: string) {
      delete eventCopyUuidMap.value[uuid]
      removeEvent(uuid)
    }

    function isEventDiff(uuid: string) {
      const origEvent = data.value.event.find((event) => event.uuid === uuid)
      if (!origEvent) return false

      return !isEqual(origEvent, eventCopyUuidMap.value[uuid])
    }

    function onSaveEvent(uuid: string) {
      delete eventCopyUuidMap.value[uuid]._new
      addEvent(eventCopyUuidMap.value[uuid])
    }

    async function onUpdateEvent(uuid: string) {
      await updateEvent(eventCopyUuidMap.value[uuid])
    }

    const luppTracks = computed(() => {
      return Object.values(
        luppData.value.track
          .map((t) => ({
            groupNumber: t.groupNumber,
            description: t.description,
          }))
          .reduce<{
            [key: string]: { groupNumber: number; description: string }
          }>((acc, track) => {
            if (!acc[track.description]) {
              acc[track.description] = track
            }

            return acc
          }, {})
      )
    })

    return {
      can,
      isPrintMode,
      isEventDiff,
      eventCopyMap,
      eventCopyUuidMap,
      data,
      columns,
      onAddEvent,
      onUpdateEvent,
      deleteConfirmModal,
      onRemoveEvent,
      luppTracks,
      onConfirmDelete,
      onSaveEvent,
      loading,
      requiredNotEmpty,
      events,
      filterEvents,
    }
  },
})
