<template>
  <q-card style="min-width: 400px">
    <q-card-section class="row items-center text-h6">
      Ta bort händelse rapport
    </q-card-section>
    <q-card-section>
      <p>Är du säker på att du vill ta bort händelse rapporten?</p>
    </q-card-section>
    <q-card-actions align="right">
      <q-btn
        flat
        label="Avbryt"
        @click="deleteModal.closeModal"
        type="button"
      />
      <q-btn label="Ta bort" type="button" color="red" @click="onConfirm" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'DailyReportEventDeleteModal',

  emits: ['confirm-delete'],

  setup(props, { emit }) {
    const deleteModal = inject<UseModal<string>>(
      'daily-report-delete-event-modal'
    )

    async function onConfirm() {
      emit('confirm-delete', deleteModal?.state.data)

      deleteModal?.closeModal()
    }

    return {
      deleteModal,
      onConfirm,
    }
  },
})
</script>
