import { render } from "./DailyReportEventTable.vue?vue&type=template&id=6379a660"
import script from "./DailyReportEventTable.vue?vue&type=script&lang=ts"
export * from "./DailyReportEventTable.vue?vue&type=script&lang=ts"

import "./DailyReportEventTable.vue?vue&type=style&index=0&id=6379a660&lang=scss"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QTable,QBtn,QTd,QInput,QSelect,QItem,QItemSection,QItemLabel,QIcon,QField});
